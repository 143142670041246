import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Gotham-Book, Arial, sans-serif',
    fontWeight: 'normal',
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#0F161F',
    },
    secondary: {
      main: '#C7C7C7',
    },
    text: {
      primary: '#333333',
      secondary: '#A8744B',
      white: '#fff',
    },
  },
});

theme.overrides = {
  MuiContainer: {
    root: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '3vw',
        paddingRight: '3vw',
      },
    },
  },
  MuiCssBaseline: {
    '@global': {
      html: {
        WebkitFontSmoothing: 'auto',
      },
      body: {
        backgroundColor: '#fff',
      },
    },
  },
  MuiTypography: {
    root: {},
    h1: {
      fontFamily: 'Gotham-Medium, Arial, sans-serif',
      color: '#A8744B',
      letterSpacing: '2px',
      fontSize: '2.625rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '5rem',
      },
    },
    h2: {
      fontFamily: 'Gotham-Medium, Arial, sans-serif',
      fontSize: '1.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2.625rem',
      },
    },
    h3: {
      fontFamily: 'Gotham-Medium, Arial, sans-serif',
      fontSize: '1.25rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
    },
    h4: {
      fontFamily: 'Gotham-Medium, Arial, sans-serif',
      fontSize: '2rem',
    },
    h5: {
      fontFamily: 'Gotham-Medium, Arial, sans-serif',
      fontSize: '1.25rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.75rem',
      },
    },
    h6: {
      fontFamily: 'Gotham-Bold, Arial, sans-serif',
      fontSize: '2rem',
    },
    body1: {
      fontSize: '0.875rem',
      marginBottom: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem',
        marginBottom: '1.5rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      marginBottom: '1.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem',
      },
    },
    button: {
      fontFamily: 'Gotham-Bold, Arial, sans-serif',
      textTransform: 'uppercase',
      textDecoration: 'none',
      fontSize: '0.875rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem',
      },
    },
    subtitle1: {
      fontFamily: 'Gotham-Bold, Arial, sans-serif',
    },
    subtitle2: {
      fontFamily: 'Gotham-Bold, Arial, sans-serif',
      fontSize: '0.875rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
    },
    caption: {
      fontFamily: 'Gotham-Medium, Arial, sans-serif',
      fontSize: '2rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2.625rem',
      },
    },
  },
};

//theme = responsiveFontSizes(theme);

export default theme;
